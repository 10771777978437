<template>
  <p
    class="caption"
    :class="{ 'caption--big': big }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'VCaption',

  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.caption
  width: 100%
  max-width: calc(360px + var(--gutter) * 2)
  margin: var(--gutter) auto
  padding: 0 var(--gutter)
  box-sizing: border-box
  text:
    align: center
    transform: uppercase
  font-size: 10px

  &--big
    font:
      weight: bold
      size: 24px
    letter-spacing: -0.03em
</style>
