var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"app"},[_c('v-header',{attrs:{"fixed":""}}),_c('v-cover',{staticClass:"app__cover app__cover--hero",attrs:{"src":require('@/assets/videos/cover-1.mp4'),"poster":require('@/assets/images/cover-1.jpg'),"ratio":-1}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Saint Laurent Fall 21 ")]),_c('v-figure',{staticClass:"app__figure",attrs:{"src":require('@/assets/images/figure-1@1x.jpg'),"media-link":"https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_036","srcset":("\n      " + (require('@/assets/images/figure-1@0.5x.jpg')) + " 323w,\n      " + (require('@/assets/images/figure-1@1x.jpg')) + " 645w,\n      " + (require('@/assets/images/figure-1@1.5x.jpg')) + " 968w\n    "),"sizes":"(max-width: 414px) 80vw, 50vw","ratio":4 / 5}}),_c('v-caption',{staticClass:"app__caption"}),_c('v-slideshow',{staticClass:"app__slideshow app__slideshow--triptych",attrs:{"gutters":"","slides":[
      {
        src: require('@/assets/images/slideshow-1@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-1@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-1@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-1@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw',
        mediaLink: 'https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_058'
      },
      {
        src: require('@/assets/images/slideshow-2@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-2@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-2@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-2@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw'
      },
      {
        src: require('@/assets/images/slideshow-3@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-3@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-3@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-3@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw',
        mediaLink: 'https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_058'
      }
    ]}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Béatrice Dalle ")]),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"gutters":"","wrap":"","slides":[
      {
        src: require('@/assets/images/slideshow-4@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-4@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-4@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-4@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      },
      {
        src: require('@/assets/images/slideshow-5@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-5@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-5@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-5@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      }
    ]}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Sihana Shalaj ")]),_c('v-cover',{staticClass:"app__cover",attrs:{"media-link":"https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_033","src":require('@/assets/images/cover-2@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/cover-2@0.5x.jpg')) + " 720w,\n      " + (require('@/assets/images/cover-2@1x.jpg')) + " 1440w,\n      " + (require('@/assets/images/cover-2@1.5x.jpg')) + " 2160w\n    "),"sizes":"80vw","ratio":3 / 2}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Abbey Lee ")]),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"gutters":"","wrap":"","slides":[
      {
        src: require('@/assets/images/slideshow-6@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-6@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-6@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-6@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw',
        mediaLink: 'https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_037'
      },
      {
        src: require('@/assets/images/slideshow-7@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-7@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-7@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-7@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw',
        mediaLink: 'https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_037'
      }
    ]}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Talia Ryder ")]),_c('v-cover',{staticClass:"app__cover",attrs:{"src":require('@/assets/images/cover-3@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/cover-3@0.5x.jpg')) + " 720w,\n      " + (require('@/assets/images/cover-3@1x.jpg')) + " 1440w,\n      " + (require('@/assets/images/cover-3@1.5x.jpg')) + " 2160w\n    "),"sizes":"80vw","ratio":4 / 5}}),_c('v-caption',{staticClass:"app__caption"}),_c('v-figure',{staticClass:"app__figure",attrs:{"src":require('@/assets/images/figure-2@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/figure-2@0.5x.jpg')) + " 323w,\n      " + (require('@/assets/images/figure-2@1x.jpg')) + " 645w,\n      " + (require('@/assets/images/figure-2@1.5x.jpg')) + " 968w\n    "),"sizes":"(max-width: 414px) 80vw, 40vw","ratio":4 / 5,"mediaLink":"https://www.ysl.com/search?cgid=men-fall-winter-21-looks&look=2021_3_20_061"}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Lakeith Stanfield ")]),_c('v-cover',{staticClass:"app__cover app__cover--gutters",attrs:{"src":require('@/assets/videos/cover-4.mp4'),"poster":require('@/assets/images/cover-4.jpg'),"ratio":8 / 5}}),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"gutters":"","wrap":"","slides":[
      {
        src: require('@/assets/images/slideshow-8@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-8@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-8@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-8@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw',
        mediaLink: 'https://www.ysl.com/search?cgid=men-fall-winter-21-looks&look=2021_3_20_025'
      },
      {
        src: require('@/assets/images/slideshow-9@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-9@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-9@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-9@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 40vw'
      }
    ]}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Romeo Beckham ")]),_c('v-figure',{staticClass:"app__figure",attrs:{"src":require('@/assets/images/figure-3@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/figure-3@0.5x.jpg')) + " 323w,\n      " + (require('@/assets/images/figure-3@1x.jpg')) + " 645w,\n      " + (require('@/assets/images/figure-3@1.5x.jpg')) + " 968w\n    "),"sizes":"(max-width: 414px) 80vw, 50vw","ratio":4 / 5,"media-link":"https://www.ysl.com/search?cgid=w-fall21-looks&look=2021_3_21_054"}}),_c('v-caption',{staticClass:"app__caption"}),_c('v-cover',{staticClass:"app__cover app__cover--gutters",attrs:{"src":require('@/assets/videos/cover-5.mp4'),"poster":require('@/assets/images/cover-5.jpg'),"ratio":8 / 5}}),_c('v-caption',{staticClass:"app__caption"},[_vm._v(" Indya Moore ")]),_c('v-caption',{staticClass:"app__caption app__caption--cta"},[_c('a',{staticClass:"app__cta-link",attrs:{"href":"https://ysl.com"}},[_vm._v("Buy the selection")])]),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }